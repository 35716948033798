import { API_BASE_URL } from '../globals';
import { trackSignUp } from './analytics';
import { fetcher } from './fetcher';

export async function validateAndTrackSignUp(params: {
  event: 'sign_up_email' | 'sign_up_facebook' | 'sign_up_apple' | 'sign_up_google';
  fbEventId: string;
  token: string;
}): Promise<void> {
  const me = await fetcher(`${API_BASE_URL}/users/me`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${params.token}` },
  });
  await trackSignUp({
    email: me.result.user.email,
    event: params.event,
    userId: me.result.user.id,
    fbEventId: params.fbEventId,
  });
}
